import { i18n } from "@lingui/core"

export function parseField(value: string) {
  if (i18n.locale === "es-AR") {
    if (!isNaN(parseInt(value))) {
      return value.replace(/[^\d]/gi, "")
    } else if (value === "") {
      return value
    } else {
      return ""
    }
  } else if (i18n.locale === "es-MX") {
    return value.replace(/[^a-zA-Z0-9]/gi, "")
  }
  return value
}

export function parseFieldWithPattern(
  value: string,
  pattern: RegExp,
  valueToReplace: string
) {
  return value?.replace(pattern, valueToReplace) ?? ""
}

export function parsePhoneNumberField(value: string) {
  return parseFieldWithPattern(value, /[^0-9^+^-\d-]/g, "")
}
