import { Helmet } from "react-helmet"
import { useProductStore } from "../../provider/ProductStoreProvider"

export function ProductVIPHead() {
  const canonicalHref = window.location.href.split("?")[0]

  const { product } = useProductStore()

  const headTitle =
    product.title && `${product.title} - Comprar en ${window.STORE_NAME}`
  const descriptionInfo = product.description ? product.description : ""
  const ogImage = product.images[0]?.thumbnails.small

  return (
    <Helmet>
      <title>{headTitle}</title>
      <link rel="canonical" href={canonicalHref} />
      <meta name="description" content={descriptionInfo} />
      <meta property="og:site_name" content={window.STORE_NAME} />
      <meta property="og:type" content="product" />
      <meta property="og:title" content={headTitle} />
      <meta property="og:description" content={descriptionInfo} />
      <meta property="og:url" content={canonicalHref} />
      {ogImage && <meta property="og:image" content={ogImage} />}
    </Helmet>
  )
}
