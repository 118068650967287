import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { useMeta } from "./hooks/useMeta"
import { useStoreState } from "../../providers/StoreProvider"

export function ProductListHead() {
  const { categories } = useMeta()

  const canonicalHref = window.location.href.split("?")[0]

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const searchValue = searchParams.get("search")

  const categoriesNames = categories.length
    ? categories[categories.length - 1].name
    : ""

  let titleInfo
  let descriptionInfo
  const ogImage = useStoreState()[0].config.logoHeader.img
  const imageLogoParsed = ogImage ? JSON.parse(ogImage) : undefined
  const logoType = useStoreState()[0].config.logoHeader?.type

  if (searchValue !== null) {
    titleInfo = `${searchValue} - ${window.STORE_NAME}`
    descriptionInfo = `Comprá online productos como ${searchValue}. Hacé tu pedido y pagalo online.`
  } else {
    if (categories.length) {
      titleInfo = `Comprar ${categoriesNames} en ${window.STORE_NAME}`
      descriptionInfo = `Comprá online productos de ${categoriesNames}. Hacé tu pedido y pagalo online. `
    } else {
      titleInfo = `Comprá online productos en ${window.STORE_NAME}`
      descriptionInfo = `Comprá productos de ${window.STORE_NAME} por internet. Hacé tu pedido y pagalo online.`
    }
  }

  return (
    <Helmet>
      <title>{titleInfo}</title>
      <link rel="canonical" href={canonicalHref} />
      <meta name="description" content={descriptionInfo} />

      <meta property="og:site_name" content={window.STORE_NAME} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleInfo} />
      <meta property="og:description" content={descriptionInfo} />
      <meta property="og:url" content={canonicalHref} />
      {logoType === "I" && imageLogoParsed && (
        <meta property="og:image" content={imageLogoParsed.small} />
      )}
    </Helmet>
  )
}
