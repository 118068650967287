import { useFindVariantByValue } from "../../../../hooks/useFindVariantByValue"
import { useProductStore } from "../../../../provider/ProductStoreProvider"

type Props = {
  propertyValue: { propertyId: string; id: number }
}

export function useIsValueAvailable() {
  const { selectedValues } = useProductStore()
  const { findVariantByValue } = useFindVariantByValue()

  function isVariantAvailable({ propertyValue }: Props) {
    let isPossibleVariantAvailable = false

    const isSelected = selectedValues.some(
      value => value.id === propertyValue.id
    )

    const possibleVariant = findVariantByValue(propertyValue)

    if (isSelected) return isPossibleVariantAvailable
    if (possibleVariant && possibleVariant.stock > 0) {
      isPossibleVariantAvailable = true
      return isPossibleVariantAvailable
    }
    const remainingValues = selectedValues.some(
      value => value.propertyId !== propertyValue.propertyId
    )
    if (remainingValues) {
      const partialVariant = findVariantByValue({
        propertyId: propertyValue.propertyId,
        id: propertyValue.id,
      })
      return partialVariant != null && partialVariant.stock > 0
    }
    return isPossibleVariantAvailable
  }

  return { isVariantAvailable }
}
