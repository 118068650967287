import { useEffect, useState } from "react"

import { api } from "../../../api"
import { useFindVariantByValue } from "../hooks/useFindVariantByValue"
import { useProductStore } from "../provider/ProductStoreProvider"

export function useSelectedImage() {
  const { product, selectedValues } = useProductStore()
  const { images, coverImageId } = product
  const { findVariantByValue } = useFindVariantByValue()

  const [
    selectedImage,
    setSelectedImage,
  ] = useState<api.products.variations.Get.Image>(images[0])

  useEffect(() => {
    let matchedImage =
      images.find(image => image.id === coverImageId) || images[0]

    if (selectedValues.length > 0) {
      const possibleVariant = selectedValues
        .map(value => findVariantByValue(value))
        .find(variant => variant !== null)

      if (possibleVariant) {
        matchedImage =
          images.find(image => image.id === possibleVariant.imageId) ||
          matchedImage
      }
    }

    setSelectedImage(matchedImage)
  }, [coverImageId, images, selectedValues, findVariantByValue])

  return { selectedImage }
}
