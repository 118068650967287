import { Helmet } from "react-helmet"

export function NotFound() {
  return (
    <Helmet>
      <meta name="prerender-status-code" content="404" />
    </Helmet>
  )
}

export default NotFound
