import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { useStoreState } from "../../providers/StoreProvider"

type Props = {
  components: {
    id: string
    kind: string
    props: Record<string, string>
  }[]
}

export function ControllablePageHead({ components }: Props) {
  const location = useLocation()
  const [state] = useStoreState()

  const isHome = location.pathname === "/"
  const textComponent = components.filter(
    component => component.kind === "Text"
  )
  const pageName = state.config.menu.find(
    item => item.url === location.pathname
  )
  const headTitle = isHome
    ? `Tienda online de ${window.STORE_NAME}`
    : `Tienda online de ${window.STORE_NAME} - ${pageName?.name}`

  let textDescription
  if (!isHome && textComponent.length) {
    textDescription = textComponent[0].props.content
  } else {
    textDescription = `Comprá productos de ${window.STORE_NAME} por internet. Hacé tu pedido y pagalo online.`
  }

  const ogImage = useStoreState()[0].config.logoHeader?.img
  const ogImageParsed = ogImage ? JSON.parse(ogImage) : undefined
  const logoType = useStoreState()[0].config.logoHeader?.type

  return (
    <Helmet>
      <title>{headTitle}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={textDescription} />
      <meta property="og:site_name" content={window.STORE_NAME} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={headTitle} />
      <meta property="og:description" content={textDescription} />
      <meta property="og:url" content={window.location.href} />
      {logoType === "I" && ogImageParsed && (
        <meta property="og:image" content={ogImageParsed.small} />
      )}
    </Helmet>
  )
}
