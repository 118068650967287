import { SelectedValues } from "../provider/types"
import { useProductStore } from "../provider/ProductStoreProvider"

export function useFindVariantByValue() {
  const { product, selectedValues } = useProductStore()

  const findVariantByValue = (value: SelectedValues) => {
    const variant = product.variants.find(({ values }) => {
      const hasTwoProperties = product.properties.length === 2

      const valuesFromOppositeProperty = selectedValues.filter(
        selectedValue => selectedValue.propertyId !== value.propertyId
      )
      const hasSelectedValue = valuesFromOppositeProperty.length > 0

      if (hasTwoProperties && hasSelectedValue) {
        return (
          values.includes(valuesFromOppositeProperty[0].id) &&
          values.includes(value.id)
        )
      } else {
        return values.includes(value.id)
      }
    })

    return variant || null
  }

  return { findVariantByValue }
}
