import { Helmet } from "react-helmet"

export function AppHead() {
  return (
    <Helmet>
      <html lang="es" />
      <title>{window.STORE_NAME}</title>
      <meta property="og:site_name" content={window.STORE_NAME} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={window.STORE_NAME} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  )
}
